@import '../../styles/variables';
@import '../../styles/mixins';

.container {
  display: flex;
  height: 100%;
  overflow: hidden;
}

.rightSection {
  overflow: auto;
  display: flex;
  flex-direction: column;
  width: 100%;

  @include bp(m) {
    padding: 0 1.5rem;
  }

  &.noScroll {
    overflow: hidden;
  }

  .content {
    padding: 0 1.5rem 2.5rem 1.5rem;
    margin-top: 2.5rem;

    @include bp(m) {
      margin-top: 5rem;
      padding: 2.5rem 0 2.5rem 0;
    }
  }
}

.mobileDrawer {
  top: 5rem;
  position: absolute;
  width: 100%;
  background-color: rgba(255, 255, 255, 1);
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding-top: 0.625rem;
  padding-bottom: 0.625rem;
  z-index: 8;
  box-shadow: $shadow-darkest-blue 0 0.125rem 0.5rem -0.1875rem,
    $shadow-dark-blue 0rem 0.3125rem 1.375rem -0.25rem;

  .navigationGroup {
    width: 100%;
    margin-top: 1.25rem;

    .groupName {
      box-sizing: border-box;
      list-style: none;
      font-family: $jost;
      text-transform: uppercase;
      font-weight: bold;
      font-size: 12px;
      color: $black-color;
      line-height: 1.4;
      padding: 0 1.4063rem 0.5rem;
    }

    .navigationItem {
      display: flex;
      color: $color-grey;
      width: 100%;
      justify-content: flex-start;
      padding: 0.875rem 1.5rem;
      align-items: center;
      position: relative;
      box-sizing: border-box;
      outline: 0;
      border: 0;
      cursor: pointer;
      user-select: none;
      vertical-align: middle;
      appearance: none;
      text-decoration: none;
      font-family: $jost;
      font-size: 14px;
      line-height: 1.75;
      min-width: 4rem;
      border-radius: 0;
      transition: background-color 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms,
        box-shadow 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms,
        border-color 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms,
        color 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
      font-weight: 500;
      text-transform: none;
      margin: 0 0 0.25rem;

      &:hover {
        background-color: $blue-background;
      }

      &.activeSubItem {
        background-color: $darker-blue-background;
        color: $primary-main;
        font-weight: 600;
      }

      .navigationIcon {
        margin-right: 0.625rem;
      }
    }
  }
}

.locationSelectModal {
  :global(.MuiDialog-paperScrollPaper) {
    max-width: 37.5rem !important;
  }
}

.publicContainer {
  @include bp(m) {
    min-height: 100vh;
  }
}
