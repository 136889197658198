@import "../../styles/variables";
@import "../../styles/mixins";

.footerContainer {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  background-color: $primary-darker;

  .footerContent {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    align-items: center;
    height: 100%;
    width: 100%;
    padding: 30px;

    @include bp(ml) {
      flex-wrap: nowrap;
      padding-left: 70px;
      padding-right: 70px;
    }

    .leftContent {
      display: flex;
      flex-direction: column;
      gap: 20px;
      max-width: 400px;

      img {
        width: 200px;

        @include bp(xs) {
          width: 300px;
        }

      }

      .description {
        font-family: $dmSans;
        font-weight: 400;
        font-size: 16px;
        line-height: 26px;
        color: $color-white;
      }

      .socials {
        display: flex;
        gap: 16px;

        .label {
          font-family: $dmSans;
          font-weight: 700;
          font-size: 14px;
          line-height: 20px;
          color: $color-white;
          padding-right: 8px;
        }

        .socialIcon {
          cursor: pointer;
          color: $color-white;
        }
      }
    }

    .rightContent {
      display: flex;
      flex-wrap: wrap;
      width: 100%;

      @include bp(s) {
        justify-content: space-around;
      }

      @include bp(ml) {
        margin-left: 20px;
      }

      @include bp(xxxl) {
        margin-left: 240px;
      }

      .columnInfo {
        width: 25%;
        min-width: 240px;
        display: flex;
        flex-direction: column;
        padding-top: 25px;

        @include bp(l) {
          padding-top: 0;
        }

        .columnTitle {
          font-family: $josefinSans;
          font-weight: 500;
          font-size: 16px;
          line-height: 22px;
          color: $color-white;
          margin-bottom: 20px;
        }

        .columnItems {
          display: flex;
          flex-direction: column;
          gap: 10px;

          .columnItem {
            font-family: $dmSans;
            font-weight: 400;
            font-size: 14px;
            line-height: 20px;
            color: rgba(255, 255, 255, 0.5);
            display: flex;
            align-items: center;

            &.fullOpacity {
              color: $color-white;
            }

            .icon {
              margin-right: 20px;
            }

            .workingHoursDay {
              width: 50px;
            }

            .workingHours {
              color: $color-white;
            }
          }
        }
      }
    }
  }

  .bottomLine {
    background-color: $primary-dark;
    width: 100%;
    height: 54px;
    display: flex;
    align-items: center;
    justify-content: center;
    font-family: $dmSans;
    font-weight: 400;
    font-size: 14px;
    line-height: 20px;
    color: $color-white;
    gap: 12px;
    padding-left: 6px;
    padding-right: 6px;
    text-align: center;

    .ababaContainer {
      margin-top: 1px;
      display: flex;
      flex-direction: row;
      align-items: center;
      font-size: 12px;
      line-height: 18px;
      gap: 8px;
      font-family: $dmSans;

      .ababaLink {
        height: 20px;
      }

      img {
        height: 100%;
      }
    }
  }
}
