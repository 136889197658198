@import "../../../styles/variables";
@import "../../../styles/mixins";

.eurListContainer {
  font-family: $josefinSans;
  color: $color-white;
  background-image: linear-gradient(rgba(32, 36, 34, 0.24), rgba(32, 36, 34, 0.24)),
    url("../../../assets/modal_background.png");
  background-size: cover;
  background-repeat: no-repeat;
  display: flex;
  flex-direction: column;
  gap: 3rem;

  .closeButton {
    color: $color-white;
    margin: 15px;
    align-self: flex-end;

    svg {
      transform: scale(1.5);
    }
  }

  .missingData {
    font-size: 18px;
    margin-top: 12px;
    color: rgb(255, 193, 7);
    background-color: rgba(255, 193, 7, 0.16);
    padding: 10px 14px;
    border-radius: 8px;
    cursor: pointer;
    width: fit-content;
    margin-left: auto;
    margin-right: auto;
    font-family: $jost;
  }

  .details {
    width: 90%;
    max-width: 1920px;
    margin: 0 auto;
    display: flex;
    flex-direction: column;
    text-align: center;

    .category {
      font-size: 14px;
      text-transform: uppercase;
      letter-spacing: 0.1em;
      font-weight: 500;
    }

    .title {
      font-size: 50px;
      padding: 40px 0;
    }

    .description {
      font-family: $dmSans;
      font-size: 16px;
      line-height: 27px;
      padding-bottom: 80px;
    }

    .list {
      display: grid;
      gap: 2rem;
      grid-auto-columns: 1fr;
      padding-bottom: 80px;

      @include bp(sm) {
        grid-template-columns: 1fr 1fr;
      }

      @include bp(l) {
        grid-template-columns: 1fr 1fr 1fr;
      }

      @include bp(xxl) {
        grid-template-columns: 1fr 1fr 1fr 1fr;
      }
    }
  }
}
