@import '../../../styles/mixins';
@import '../../../styles/variables';

.content {
  letter-spacing: 0.1em;
  text-transform: uppercase;
  padding-top: 100px;
  font-size: 14px;
  font-family: $josefinSans;
  font-weight: 500;
  text-align: center;
}

.orderDetails {
  background-color: $primary-main;
  font-size: 22px;
  margin-top: 30px;
  margin-bottom: 20px;
  width: 100%;
  text-align: center;
  padding: 30px;

  .price {
    font-weight: 500;
    margin-top: 30px;
  }

  .eur {
    text-transform: lowercase;
  }
}

.contentTitle {
  font-family: $josefinSans;
  font-size: 0.9rem;
  font-weight: 500;
  letter-spacing: 0.1em;
  text-transform: uppercase;
  text-align: center;
}

.contentSubTitle {
  background-color: $primary-main;
  padding: 1rem 0;
  font-family: $josefinSans;
  font-size: 2rem;
  letter-spacing: 0.1em;
  text-align: center;
  text-transform: none;

  @include bp(sm) {
    font-size: 4rem;
  }
}

.contentStatement {
  font-family: $dmSans;
  font-size: 1.4rem;
  text-align: center;
  text-transform: none;
}

.confirm {
  margin-top: 60px !important;
  text-align: center;

  .cartIcon {
    display: flex;
    padding-left: 10px;
  }
}

.message {
  padding: 20px;
  background-color: $primary-main;
  width: 100%;
  text-align: center;
  position: absolute;
  top: 50%;
}
