@import '../../styles/variables';
@import '../../styles/mixins';

.navigationContainer {
  display: flex;
  align-items: center;
  z-index: 8;
  height: 6.25rem;
  position: absolute;
  width: 100%;

  @include bp(xxs) {
    height: 7.5rem;
  }

  .userWalletContainer {
    white-space: nowrap;
    cursor: pointer;
  }

  .walletAmount {
    color: $primary-darkest;
  }

  .lightWalletAmount {
    color: $bright-color;
  }

  &.isPopoverOpen {
    @include bp(ml) {
      padding-right: 0.9375rem;
    }
  }

  .innerNavigation {
    display: flex;
    justify-content: space-between;
    align-items: center;
    height: 5rem;
    width: 100%;
    border-top: 1px solid #cecdcb;
    border-bottom: 1px solid #cecdcb;
    box-sizing: border-box;
    padding-left: 1rem;
    padding-right: 0.9375rem;

    @include bp(xxs) {
      height: 3.125rem;
    }

    @include bp(s) {
      padding-left: 70px;
      padding-right: 70px;
    }

    @include bp(m) {
      height: 50px;
    }

    &.lightBorder {
      border-top: 1px solid $color-grey-border;
      border-bottom: 1px solid $color-grey-border;
    }

    svg {
      color: $bright-color;
    }

    .logoImage {
      object-fit: cover;
      height: 3rem;
      width: 6rem;

      @include bp(xxs) {
        height: 4rem;
        width: 8rem;
      }

      @include bp(s) {
        height: 6rem;
        width: 11rem;
      }

      @include bp(l) {
        margin-top: 2rem;
        height: 8rem;
        width: 15rem;
      }
    }

    .iconItems {
      display: flex;
      gap: 0.5rem;
      align-items: center;
      flex-wrap: wrap-reverse;
      justify-content: flex-end;

      @include bp(xs) {
        gap: 1rem;
        align-items: center;
        flex-wrap: nowrap;
        justify-content: center;
      }

      .drawerIcon {
        color: $primary-darkest;

        &.lightIcon {
          color: $bright-color;
        }
      }

      .iconUser div {
        display: flex;
      }
    }

    .navigationItems {
      display: flex;
      flex-direction: row;
      gap: 20px;

      @include bp(l) {
        gap: 34px;
      }

      a {
        font-style: normal;
        font-weight: 400;
        font-size: 15px;
        line-height: 24px;
        color: $primary-darkest;
        font-family: $jost;
        letter-spacing: 0.03em;
        text-transform: uppercase;

        &.isActive {
          text-decoration-color: $primary-main;
          text-decoration-thickness: 2px;
          text-underline-offset: 19px;
          text-decoration-line: underline;
        }

        &:hover {
          text-shadow: 2px 2px 4px $label;
        }
      }

      &.lightLinks {
        a {
          color: $bright-color;

          &:hover {
            text-shadow: 2px 2px 4px $black-color;
          }
        }
      }
    }

    .actionContainer {
      display: flex;
      gap: 32px;
      flex-direction: row;
      align-items: center;

      .iconButtonsContainer {
        display: flex;
        align-items: center;
        gap: 1.2rem;

        a {
          color: $primary-darkest;
        }

        svg {
          height: 2rem;
          width: 1.2rem;
          cursor: pointer;
        }

        &.lightIcons {
          a {
            color: $bright-color;
          }
        }
      }

      .logoutButton {
        cursor: pointer;
        color: $primary-darkest;
      }

      .lightLogout {
        cursor: pointer;
        color: $bright-color;
      }
    }

    .mobileMenu {
      top: 80px;
      left: 0;
      position: absolute;
      width: 100%;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      padding-top: 10px;
      padding-bottom: 10px;
      z-index: 8;
      box-shadow: rgba(34, 51, 84, 0.2) 0 2px 8px -3px,
        rgba(34, 51, 84, 0.1) 0px 5px 22px -4px;
      background-color: $primary-main;

      .navigationItem {
        display: flex;
        width: 100%;
        justify-content: center;
        padding: 14px 24px;
        align-items: center;
        position: relative;
        box-sizing: border-box;
        outline: 0;
        border: 0;
        cursor: pointer;
        user-select: none;
        vertical-align: middle;
        appearance: none;
        text-decoration: none;
        font-family: $dmSans;
        min-width: 64px;
        border-radius: 0;
        transition: background-color 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms,
          box-shadow 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms,
          border-color 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms,
          color 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
        text-transform: none;
        margin: 0 0 12px;
        font-style: normal;
        font-weight: 400;
        font-size: 15px;
        line-height: 18px;
        color: $bright-color;

        @include bp(m) {
          &:hover {
            background-color: $primary-dark;
          }
        }

        &.activeSubItem {
          background-color: $primary-dark;
          color: $bright-color;
          font-weight: 600;
        }

        .navigationIcon {
          margin-right: 10px;
        }
      }
    }
  }
}
