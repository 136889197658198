@import "../../../styles/mixins";
@import "../../../styles/variables";

.content {
  padding: 8rem 0;
  display: flex;
  flex-direction: column;
  gap: 1rem;

  p {
    margin: 0;
    padding: 0;
  }

  .contentTitle {
    font-family: $josefinSans;
    font-size: 0.9rem;
    font-weight: 500;
    letter-spacing: 0.1em;
    text-transform: uppercase;
    text-align: center;
  }

  .contentSubTitle {
    background-color: $primary-main;
    padding: 1rem 0;
    font-family: $josefinSans;
    font-size: 2rem;
    letter-spacing: 0.1em;
    text-align: center;

    @include bp(sm) {
      font-size: 4rem;
    }
  }

  .contentStatement {
    font-family: $dmSans;
    font-size: 1.4rem;
    text-align: center;
  }

  .contentBookedTimesContainer {
    width: 60%;
    margin: 0 auto;
    padding: 2rem 0;
    display: flex;
    flex-direction: column;
    gap: 0.5rem;
    justify-content: center;
    background-color: $primary-main;

    @include bp(sm) {
      width: 50%;
    }

    .contentBookedTimestext {
      text-align: center;
      font-family: $dmSans;

      @include bp(sm) {
        display: flex;
        justify-content: center;
        gap: 0.5rem;
        font-size: 1.25rem;
      }
    }
  }
}
