@import '../../../styles/variables';
@import '../../../styles/mixins';

.eurPackageContainer {
  background-color: rgba(0, 0, 0, 0.3);
  border: .0625rem dashed $color-white;
  margin: 1.25rem;
  border-radius: 1.625rem;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 1.625rem;
  font-family: $dmSans;
  backdrop-filter: blur(.5313rem);

  &:hover {
    background-color: rgba(43, 111, 78, 0.67);
  }

  .price {
    font-size: 2.8125rem;
    padding-top: 1.5625rem;
    font-family: $josefinSans;
  }

  .eur {
    size: .9375rem;
    text-transform: lowercase;
    padding: 1.5625rem 0;
    border-bottom: .0625rem solid $color-white;
    width: 100%;
    text-align: center;
  }

  .customEur {
    size: .94rem;
    padding: .315rem 0 .75rem 0;
    border-bottom: .065rem solid $color-white;
    width: 100%;
    text-align: center;

    input {
      color: $color-white;
    }
  }

  .description {
    line-height: 1.6875rem;
  }

  .orderButton {
    width: 100%;
    display: flex;
    flex-basis: 100%;

    button {
      align-self: flex-end;
      width: 100%;
    }

    .cartIcon {
      color: $primary-main;
      display: flex;
      padding-left: .625rem;
      justify-content: center;
      margin-bottom: .1062rem;

      div {
        display: flex;
      }
    }
  }
}
