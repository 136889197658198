$jost: 'Jost', sans-serif;
$dmSans: 'DM Sans', sans-serif;
$josefinSans: 'Josefin Sans', sans-serif;

$error-text: rgb(122, 12, 46);
$error-background: rgb(255, 231, 217);
$error-icon: rgb(255, 72, 66);

$primary-light: #308059;
$primary-main: #2b6f4e;
$primary-dark: #1f4935;
$primary-darker: #14412b;
$primary-darkest: #3d3f40;

$color-white: #ffffff;

$primary-button-background: #2b6f4e;
$primary-button-background-hover: #1f4935;
$primary-button-shadow: rgba(31, 73, 53, 0.24) 0 8px 16px 0;

$color-grey-border: rgba(240, 244, 245, 0.8);

$color-grey: rgb(126, 136, 143);
$color-grey-dark: #6b6b6b;
$color-grey-light: rgb(218, 222, 227);
$color-grey-very-light: #cecdcb;

$label: rgb(145, 158, 171);
$label-focused: $primary-light;
$label-error: #d32f2f;

$input-border: rgba(145, 158, 171, 0.32);
$input-border-focused: $primary-light;
$input-border-error: $label-error;
$input-error-text: $label-error;

$background-color: #e8e9eb;
$background-grey: #f7f7f7;
$dark-background: #333333;

$separator-color: rgba(227, 87, 36, 0.2);
$input-border-color: rgba(227, 87, 36, 0.5);
$placeholder-color: rgba(227, 87, 36, 0.6);

$very-light-green: #eef6df;

$bright-color: #ffffff;
$black-color: #000000;

$red-color: #e13119;
$red-color-darker: #e04321;
$red-color-brighter: #efc5bd;

$warning-color: #f19a3e;
$warning-color-brighter: #f6c48d;
$warning-color-darker: #98540b;

$sidebar-color: #313638;
$navigation-color: #313638;

$accent: #006891;
$accentHover: #005b7e;
$accentSecondary: #ccff00;
$accentLight: rgba(0, 104, 145, 0.7);
$accentLightest: rgba(0, 104, 145, 0.3);
$accentSecondaryHover: #abd705;
$accentSecondaryLightest: rgba(204, 255, 0, 0.3);

$colorRed: #db2955;
$colorGreen: #2e933c;
$colorOrange: #e49b0f;
$colorBlue: #296ddb;

$color-green-dark: #2b6f4eb5;
$color-grey-medium: #444c61;
$color-modal-grey: rgb(102, 100, 100);
$color-modal-content-black: #00000042;

$shadow-darkest-blue: rgba(34, 51, 84, 0.2);
$shadow-dark-blue: rgba(34, 51, 84, 0.1);

$blue-background: rgba(25, 118, 210, 0.06);
$darker-blue-background: rgba(25, 118, 210, 0.12);

$transparent-grey: rgba(32, 36, 34, 0.24);
$transparent-white: rgba(255, 255, 255, 0.82);
$transparent-green: rgba(43, 111, 78, 0.67);
$transparent-black: rgba(0, 0, 0, 0.26);

$invoice-download-color: rgba(34, 51, 84, 0.9);
