@import '../../styles/mixins';
@import '../../styles/variables';

.modalContainer {
  .modalTitle {
    display: flex;
    justify-content: space-between;
  }

  ::-webkit-scrollbar {
    width: 10px;
    background-color: $primary-dark;
  }
  ::-webkit-scrollbar-thumb {
    background-color: $primary-light;
  }

  :global(.MuiDialog-paperScrollPaper) {
    border-radius: 16px;
    background-color: rgb(255, 255, 255);
    color: rgb(33, 43, 54);
    transition: box-shadow 300ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
    background-image: none;
    margin: 0;
    position: relative;
    overflow-y: auto;
    display: flex;
    flex-direction: column;
    max-height: calc(100% - 64px);
    max-width: 1200px;
    width: 100%;
    box-shadow: rgba(145, 158, 171, 0.24) 0 0 4px 0,
      rgba(145, 158, 171, 0.24) 0 24px 48px 0;

    @include bp(s) {
      min-width: 600px;
      margin: 32px;
    }

    h2 {
      margin: 0;
      font-weight: 700;
      line-height: 1.55556;
      font-family: $jost;
      flex: 0 0 auto;
      padding: 24px 24px 0;
      font-size: 1.0625rem;

      @include bp(s) {
        font-size: 1.125rem;
      }
    }

    :global(.MuiDialogContent-root) {
      padding: 6px 24px 24px;
      flex: 1 1 auto;
      overflow-y: auto;
      border-top: 0;
      border-bottom: 0;

      :global(.MuiDialogContentText-root) {
        margin: 0;
        line-height: 1.5;
        font-size: 0.875rem;
        font-family: $jost;
        font-weight: 400;
        color: rgb(99, 115, 129);
      }
    }

    :global(.MuiDialogActions-root) {
      display: flex;
      align-items: center;
      justify-content: flex-end;
      flex: 0 0 auto;
      padding: 24px;
    }
  }

  &.isSmall {
    :global(.MuiDialog-paperScrollPaper) {
      max-width: 600px;
    }
  }

  &.isBig {
    .modalTitle {
      justify-content: flex-end;
      padding-top: 0;
    }

    :global(.MuiDialog-paperScrollPaper) {
      max-width: 90vw;
      margin: 0;
      border-radius: 0;
    }

    :global(.MuiDialogContent-root) {
      padding: 0;
    }
  }

  &.purchaseModal {
    margin-left: 20px;
    margin-right: 20px;

    .modalTitle {
      justify-content: flex-end;
      padding-top: 0;
    }

    :global(.MuiDialog-paperScrollPaper) {
      margin: 0;
      border-radius: 0;
    }

    :global(.MuiDialogContent-root) {
      padding: 0;
    }
  }
}
