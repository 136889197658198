@import "../../../styles/mixins";
@import "../../../styles/variables";

.purchaseContainer {
  background-image: linear-gradient(rgba(0, 0, 0, 0.26), rgba(0, 0, 0, 0.26)),
    url("../../../assets/purchase_modal_background.png");
  background-size: cover;
  background-repeat: no-repeat;
  height: 90vh;
  color: $color-white;
  display: flex;
  flex-direction: column;
  font-family: $dmSans;
  overflow: hidden;

  @include bp(m) {
    min-height: 700px;
  }

  .closeButton {
    color: $color-white;
    margin: 15px;
    align-self: flex-end;

    svg {
      transform: scale(1.5);
    }
  }
}
